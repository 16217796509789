.sikkaBtn {
  border: none;
  outline: none;
  padding: 0.7rem 2rem;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #ffffff;
  cursor: pointer;
}

.gradient {
  background: linear-gradient(
    to right,
    var(--btn-gradient-1),
    var(--btn-gradient-2)
  ) !important;
}

.proBtn {
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-light-color)
  ) !important;
  color: #1b2333;
}

@media screen and (max-width: 500px) {
  .sikkaBtn {
    font-size: 14px !important;
    padding: 6px 10px !important;
  }
}
