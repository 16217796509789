:root {
  --font-family: 'Space Grotesk', sans-serif;
  --primary-color: #ffc165;
  --primary-light-color: #f9f9db;
  --title-color: #ffffff;
  --title-color-dark: #192130;
  --text-light-color: #ffffff60;
  --subtitle-color: #acc9d8;
  --subtitle-color-dark: #4c5058;
  --gradient-bg-1: #232d40;
  --gradient-bg-2: #171e2d;
  --btn-gradient-1: #2e4879;
  --btn-gradient-2: #142240;
  --card-gradient-1: #171e2e;
  --card-gradient-2: #232d40;
  --link-color: #ffffff;
  --link-font-weight: 400;
  --link-decoration: none;
  --icon-color: #ffffff;
  --input-bg-color: #171e2d;
  --error-color: #ff7c7c;
  --offer-color: #ffc968;
  --orange-color: #f5b75e;
  --red-color: #ff3939;
  --green-color: #00d95f;
}

*html {
  font-family: var(--font-family);
}
html {
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #ebdfcda2;
  }
}
